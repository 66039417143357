.container {
  position: relative;
}

.progress {
  position: absolute;
  right: 4em;
  top: 2.8em;
  width: 2.2em;
}


@media (max-width: 768px) {

  .progress {
    position: absolute;
    right: 1.5em;
    top: 2.4em;
    width: 2em;
  }

  .body {
    padding: 3em 3em 0 3em;
  }
}