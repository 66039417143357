.header {
  width: 30em;
}

.button {
  margin: 3em 0;
}

.subtitle {
  font-weight: 100;
}

.body {
  padding: 2.5em;
  box-sizing: border-box;
}

@media (max-width: 1200px) {
  .body {
    width: 80%;
  }

  .header {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .header {
    width: 100%;
  }

  .body{
    width: 100%;
  }

  .button {
    margin: 1.5em 0 0 0;
  }
}
